export default [
  {
    path: '/',
    name: 'aqua-main',
    component: () => import('@/views/aqua/main/Main.vue'),
  },
  {
    path: '/dentist',
    name: 'aqua-dentist',
    component: () => import('@/views/aqua/specialties/Dentist.vue'),
  },
  {
    path: '/join-form',
    name: 'aqua-form',
    component: () => import('@/views/aqua/specialties/components/FormSection.vue'),
  },
]
